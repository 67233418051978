<template>
  <div class="wrapper">
    <van-cell-group>
      <van-cell title="头像" center>
        <van-image
          round
          width="64px"
          height="64px"
          :src="detail.imgUrl"
          v-if="detail.imgUrl !== 'null'"
        />
        <div class="img" v-else>
          <i class="rmy-icon rmy-icon-touxiang"></i>
        </div>
      </van-cell>
      <van-cell title="用户名" :value="detail.name" />
      <van-cell title="手机号" :value="detail.phone" />
      <van-cell title="所属角色" :value="detail.role" />
      <van-cell title="所属单位" :value="detail.dept" />
      <van-cell
        title="修改密码"
        is-link
        :to="{ name: 'modify', params: detail }"
      />
    </van-cell-group>
  </div>
</template>

<script  lang="ts">
import { onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
export default {
  setup() {
    const detail = reactive({
      imgUrl: "",
      name: "",
      phone: "",
      role: "",
      dept: "",
      userId: "",
      roleId: "",
    });
    const route = useRoute();
    onMounted(() => {
      detail.imgUrl = (route.params as any).imgUrl;
      console.log("🚀 ~ file: Detail.vue ~ line 46 ~ onMounted ~ detail.imgUrl", detail.imgUrl)
      detail.name = (route.params as any).name;
      detail.phone = (route.params as any).phone;
      detail.role = (route.params as any).role;
      detail.dept = (route.params as any).dept;
      detail.userId = (route.params as any).userId;
      detail.roleId = (route.params as any).roleId;
    });
    return {
      detail,
    };
  },
};
</script>

<style  lang="scss" scoped>
.wrapper {
  background: $BACKGROUND_COLOR;
  height: 100vh;
  /* If you need to support browser without CSS var support (<= IE11) */
  height: calc(100vh - var(--vh-offset, 0px));
  .img {
    float: right;
    width: 64px;
    height: 64px;
    line-height: 64px;
    .rmy-icon {
      font-size: 62px;
      color: #666666;
    }
  }
}
</style>