
import { onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
export default {
  setup() {
    const detail = reactive({
      imgUrl: "",
      name: "",
      phone: "",
      role: "",
      dept: "",
      userId: "",
      roleId: "",
    });
    const route = useRoute();
    onMounted(() => {
      detail.imgUrl = (route.params as any).imgUrl;
      console.log("🚀 ~ file: Detail.vue ~ line 46 ~ onMounted ~ detail.imgUrl", detail.imgUrl)
      detail.name = (route.params as any).name;
      detail.phone = (route.params as any).phone;
      detail.role = (route.params as any).role;
      detail.dept = (route.params as any).dept;
      detail.userId = (route.params as any).userId;
      detail.roleId = (route.params as any).roleId;
    });
    return {
      detail,
    };
  },
};
